(function($){

// ---------- Header START
var Header = {

  config: {
    matchHeightFlag: false,
    wideMQ: 'screen and (min-width: 80em) and (min-height: 48.125em)'
  },

  nav: {
    IS_OPEN: false
  },

  menu: {
    levels: []
  },

  init: function(){
    Header.resizeNav();
    $(window).resize($.throttle(250, Header.resizeNav));

    Header.initNav();

    Header.setHeaderStyle();
    $(window).scroll($.throttle(250, Header.setHeaderStyle));

    enquire.register('screen and (min-width: 64em)', {
      match: function() {
        $('nav').css({ marginTop: '', marginBottom: '' });
        $(window).off('resize', Header.resizeNav);
      },
      unmatch: function() {
        setTimeout(function(){ Header.resizeNav(); }, 500);
        $(window).resize($.throttle(250, Header.resizeNav));
      }
    });

    enquire.register(Header.config.wideMQ, {
      match: function() {
        Header.config.matchHeightFlag = true;

        Header.openNav();

        $(Header.menu.levels).each(function(index){
          Header.closeLevel(index);
        });

        Header.removeDefaultMenuListeners();
        Header.addWideMenuListeners();
      },
      unmatch: function() {
        Header.config.matchHeightFlag = false;

        Header.closeNav();

        Header.removeWideMenuListeners();
        Header.addDefaultMenuListeners();
      }
    });
  },

  resizeNav: function(){
    $('nav').css('margin-top', $('header').outerHeight());
    $('nav').css('margin-bottom', $('.header-link__group').outerHeight());
  },

  initNav: function(){
    $('.js-nav-toggler').on('click', Header.toggleNav);

    $('.nav-menu__link.m--lvl1').each(function(index, element){
      var toggler = $(element);
      var item = $(element).parent();
      var lvl = $(toggler.parent().find('.nav-menu__level')[0]);
      var back = $(lvl.find('.nav-menu__title')[0]);

      if(lvl.length){
        Header.menu.levels[index] = {
          item: item,
          toggler: toggler,
          lvl: lvl,
          back: back,
          IS_OPEN: false
        };

        toggler.data('level-index', index);
        item.data('level-index', index);
        back.data('level-index', index);

        Header.closeLevel(index);

        // Bind tab
        $(window).on('keyup', function (e) {
          var keyCode = e.keyCode || e.which;

          if ($(e.target).closest(lvl).length || e.target == toggler[0]) {
            if(keyCode == 9){
              Header.openLevel(index);
            }
          } else {
            Header.closeLevel(index);
          }
        }.bind(this));
      }
    });

    Header.addDefaultMenuListeners();
  },

  toggleNav: function(){
    if(!Header.nav.IS_OPEN){
      Header.openNav();
    } else {
      Header.closeNav();
    }
  },

  openNav: function(){
    $('.js-nav-toggler').addClass('is-open');
    $('#nav').addClass('is-open');
    $('body').addClass('nav-is-open');
    Header.nav.IS_OPEN = true;

    if(Header.config.matchHeightFlag === true) setTimeout($.fn.matchHeight._update, 250);

    setTimeout(function(){ $(window).trigger('afterNavOpen'); } , 250);
  },

  closeNav: function(){
    $('.js-nav-toggler').removeClass('is-open');
    $('#nav').removeClass('is-open');
    $('body').removeClass('nav-is-open');
    Header.nav.IS_OPEN = false;

    $(Header.menu.levels).each(function(index){
      Header.closeLevel(index);
    });

    if(Header.config.matchHeightFlag === true) setTimeout($.fn.matchHeight._update, 250);

    setTimeout(function(){ $(window).trigger('afterNavClose'); } , 250);
  },

  addDefaultMenuListeners: function(){
    $(Header.menu.levels).each(function(index){
      Header.menu.levels[index].toggler.on('click', Header.openLevel);
      Header.menu.levels[index].back.on('click', Header.closeLevel);
    });
  },

  removeDefaultMenuListeners: function(){
    $(Header.menu.levels).each(function(index){
      Header.menu.levels[index].toggler.off('click', Header.openLevel);
      Header.menu.levels[index].back.off('click', Header.closeLevel);
    });
  },

  addWideMenuListeners: function(){
    $(Header.menu.levels).each(function(index){
      Header.menu.levels[index].item.on('mouseover', Header.openLevel)
        .on('mouseout', Header.closeLevel);
    });
  },

  removeWideMenuListeners: function(){
    $(Header.menu.levels).each(function(index){
      Header.menu.levels[index].item.off('mouseover', Header.openLevel)
        .off('mouseout', Header.closeLevel);
    });
  },

  // toggleLevel: function(e){
  //   var index = $(this).data('level-index');

  //   if(Header.menu.levels[index].IS_OPEN){
  //     Header.closeLevel(index);
  //   } else {
  //     Header.openLevel(index);
  //   }
  // },

  openLevel: function(index){
    index = typeof index === 'object' || index === undefined ? $(this).data('level-index') : index;
    $('body').addClass('main-nav-open');
    Header.menu.levels[index].toggler.addClass('is-open');
    Header.menu.levels[index].lvl.addClass('is-open');
    Header.menu.levels[index].IS_OPEN = true;
    return false;
  },

  closeLevel: function(index){
    index = typeof index === 'object' || index === undefined ? $(this).data('level-index') : index;
    $('body').removeClass('main-nav-open');
    Header.menu.levels[index].toggler.removeClass('is-open');
    Header.menu.levels[index].lvl.removeClass('is-open');
    Header.menu.levels[index].IS_OPEN = false;
    return false;
  },

  setHeaderStyle: function(){
    if($(window).scrollTop() > 1){
      $('.header').addClass('is-min');
    } else {
      $('.header').removeClass('is-min');
    }
  }

};
// ---------- HEADER END

// Init Header
$(document).ready(Header.init);

})(jQuery);
