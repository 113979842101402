(function(){

// MAGNIFIC POPUP
$.extend(true, $.magnificPopup.defaults, {
  closeMarkup: '<button title="%title%" type="button" class="mfp-close"><svg role="img" class="svg-icon"><use xlink:href="assets/images/pictos/misc.sprite.svg#close"></use></svg></button>',
    // tClose: 'Close (Esc)', // Alt text on close button
    // tLoading: '<div class="spinner-wrapper"><div class="spinner-bubble"></div></div>', // Text that is displayed during loading. Can contain %curr% and %total% keys
    // gallery: {
    //     tPrev: 'Previous (Left arrow key)', // Alt text on left arrow
    //     tNext: 'Next (Right arrow key)', // Alt text on right arrow
    //     tCounter: '%curr% of %total%' // Markup for "1 of 7" counter
    // },
    // image: {
    //     tError: '<a href="%url%">The image</a> could not be loaded.' // Error message when image could not be loaded
    // },
    // ajax: {
    //     tError: '<a href="%url%">The content</a> could not be loaded.' // Error message when ajax request failed
    // }
    removalDelay: 300
});

})();
