(function($){

  // ---------- APP START
  var App = {

    config: {
      scrollOffset: 140,
      mapStyles: []
    },

    init: function(){
      // Init SVG polyfill
      if(typeof(svg4everybody) !== 'undefined') svg4everybody();

      // Smart text-column bg image
      $(window).resize($.throttle(500, function(){
        $('.text-column__background').each(function(){
          var $content = $(this).prev('.text-column__content');
          if ($content.length) {
            $(this).css({paddingTop: $content.outerHeight()});
          }
        });
      })).resize();

      // Init object-fill polyfill
      if(typeof(objectFitImages) !== 'undefined') objectFitImages();

      // Remove focus state when clicking on a button with a mouse
      $(':not(form) button, a').on('click', function(e){
        if(e.clientX && e.clientY)
        $(this).blur();
      });

      if(typeof(Cnil) !== 'undefined') {
        Cnil.init();
      }

      $('a[href^="#"]').on('click', function(e){
        e.preventDefault();

        var scrollTo = $(this).data('scroll-to') ? $(this).data('scroll-to') : $(this).attr('href');

        if($(scrollTo).length > 0 && $(this).data('prevent-scroll') !== true)
        App.scroll(scrollTo, $(this).data('scroll-offset'));
      });

      App.initClickableDivs();

      if($('[data-scroll]').length) App.scroll($('[data-scroll]').data('scroll'));
      if($('.page-head__menu').length) App.initPageMenu();
      if ($('.share-tool').length) App.initShareTools();
      if($('[class*="p-private-offer"]').length) App.privateOffers.init();
      if($('.js-popin-form').length) App.initPopinForm();
      if($('select').length) App.initSelects();
      if($('.js-mfp').length) App.initMfpSingleMedia();
      if($('.no-cssvhunit .portal').length) App.initPortal();
      if($('.image-slider').length) App.initImageSlider();
      if($('.portal-slider').length) App.initPortalSlider();
      if($('.content-tab').length) App.initContentTab();
      if($('.calendar').length) App.initCalendar();
      if($('.js-history').length) App.initHistoryTimeline();
      if($('.mds-list').length) App.initMDSList();

      $.fn.matchHeight._apply($('.logo'));
    },

    load: function(){
      if($('.js-key-figures').length) App.initKeyfigures();
    },

    reflow: function(context){
      var carousels = $(context).find('.carousel__view');
      if(carousels.length)
      carousels.slick('refresh');

      $.fn.matchHeight._update();
    },

    // Make div (with eventually child links) clickable
    initClickableDivs: function(context){
      var clickableDivs;

      if(context === undefined) {
        clickableDivs = $('[data-href]');
      } else if(context.data('href')){
        clickableDivs = context;
      } else {
        clickableDivs = context.find('[data-href]');
      }

      var enable = true;

      // Calculate if we need to prevent following clicks in carousels beacause of draggin
      // clickableDivs.each(function(){
      //   var move = {mousedown: 0, mouseup: 0};
      //   $(this).on('mousedown mouseup', function(e){
      //     enable = true;
      //     if($(this).closest('.slick-list').length > 0 && e.button === 0){
      //       move[e.type] = e.clientX;

      //       if(e.type === 'mouseup' && ((move.mouseup-move.mousedown) > 20 || (move.mouseup-move.mousedown) < -20)){
      //         enable = false;
      //       }
      //     }
      //   });
      // });

      clickableDivs.click(function() {
        if(enable){
          if($(this).data('href-target') == '_blank'){
            window.open($(this).data('href'), '_blank');
          } else{
            document.location.href = $(this).data('href');
          }
        }
      }).find('a').click(function(e) {
        if(enable){
          e.stopPropagation();
        }
      });
    },

    initShareTools: function(context) {
      var tools;

      if (context === undefined) {
        tools = $('.share-tool');
      } else if (context.hasClass('share-tool')) {
        tools = context;
      } else {
        tools = context.find('.share-tool');
      }

      tools.on('mouseenter', function() {
        $(this).addClass('is-open');
      });
      tools.on('mouseleave', function() {
        $(this).removeClass('is-open');
      });
    },

    initPageMenu: function(){
      var $head = $('.page-head');
      var $menu = $('.page-head__menu');
      var $breadcrumb = $('.page-head__breadcrumb');
      var $header = $('#header');

      enquire.register('screen and (min-width: 64em)', {
        match: function() {
          adjustMenu();
          $(window).resize($.throttle(250, adjustMenu));
        },
        unmatch: function() {
          $(window).off('resize', adjustMenu);
          setTimeout(removeStyles, 250);
        }
      });

      function adjustMenu(){
        if($menu.css('bottom') === 'auto'){
          removeStyles();
        }

        var limit = Math.max($breadcrumb.position().top + $breadcrumb.outerHeight(), $header.outerHeight());

        if($menu.position().top < limit){
          $menu.css({ bottom: 'auto', top: limit });
          $head.css('margin-bottom', (limit + $menu.outerHeight()) - $head.outerHeight());
        }
      }

      function removeStyles(){
        $menu.css({ bottom: '', top: '' });
        $head.css('margin-bottom', '');
      }
    },

    initCalendar: function(){
      var $daysTogglers = $('.calendar__day.has-events');
      var $daysEvents = $('.js-calendar-day-events');

      $daysTogglers.data('scroll-to', '.event-col.m--event-list');
      $('.event__body').data('is-open', false).css('display', 'none');

      $daysTogglers.on('click', function(e){
        $daysTogglers.removeClass('is-current');
        $daysEvents.hide();
        $($(this).attr('href')).fadeIn(250);
        $(this).addClass('is-current');

        if($($(this).attr('href')).find('.event').length === 1){
          $($(this).attr('href')).find('.event__body').slideDown().data('is-open', true);
          $($(this).attr('href')).find('.event__toggler').text('-');
        }

        return false;
      }).first().click();

      enquire.register('screen and (min-width: 64em)', {
        match: function() {
          $daysTogglers.data('prevent-scroll', true);
        },
        unmatch: function() {
          $daysTogglers.data('prevent-scroll', false);
        }
      });

      $('.event__toggler').on('click', function(e){
        var $body = $(this).closest('.event').find('.event__body');
        if($body.data('is-open') === false){
          $body.slideDown().data('is-open', true);
          $(this).text('-');
        } else {
          $body.slideUp().data('is-open', false);
          $(this).text('+');
        }
      });
    },

    privateOffers: {
      init: function(){
        if($('.page-head__menu').length) App.privateOffers.initUserMenu();
        if($('.main').hasClass('logged-out')) App.privateOffers.preventUnloggedUsers();

        if($('.js-mfp-po-map').length){
          $('.js-mfp-po-map').magnificPopup({
            items: {
              src: '<div class="po-map"><div class="po-map__map" id="po_map"></div></div>',
              type: 'inline',
              preload: false
            },
            mainClass: 'mfp-po-map',
            closeMarkup: '<button title="%title%" type="button" class="mfp-close">Retour à la liste <svg role="img" class="svg-icon"><use xlink:href="assets/images/pictos/misc.sprite.svg#close"></use></svg></button>',
            callbacks: {
              open: function(item){
                App.initPrivateOffersMap();
              }
            }
          });
        }
      },

      initUserMenu: function(){
        var $menu = $('.page-head__menu').clone().removeClass('page-head__menu').addClass('po-user-menu');
        var $content = $menu.find('.page-menu__list');
        var $toggler = $menu.find('.page-menu__title');

        if(!$('.main').hasClass('logged-out')){
          $toggler.append($('<svg role="img" class="svg-icon chevron-down "><use xlink:href="assets/images/pictos/misc.sprite.svg#chevron-down"></use></svg>'));

          $content.slideUp().data('is-open', false);
          $toggler.on('click', function(){
            if($content.data('is-open')){
              $content.slideUp().data('is-open', false);
              $(this).removeClass('is-open');
            } else {
              $content.slideDown().data('is-open', true);
              $(this).addClass('is-open');
            }
          });
        }

        $('.page__content').prepend($menu);

        enquire.register('screen and (min-width: 64em)', {
          match: function() {
            $menu.detach();
          },
          unmatch: function() {
            $('.page__content').prepend($menu);
            $content.slideUp().data('is-open', false);
            $toggler.removeClass('is-open');
          }
        });
      },

      preventUnloggedUsers: function(context){
        var links;

        if(context === undefined) {
          links = $('.offer-grid a');
        } else if(context.data('href')){
          links = context;
        } else {
          links = context.find('a');
        }

        links.magnificPopup({
          items: {
            src: '<p class="po-mfp-login">Vous devez être enregistré pour accéder à ce contenu. <br><a href="#" class="mfp-dismiss">Créez gratuitement votre compte.</a></p>',
            type: 'inline',
            preload: false
          },
          mainClass: 'mfp-modal',
          callbacks: {
            open: function(item){
              $(this.content).find('.mfp-dismiss').on('click', function(){
                $.magnificPopup.close();
                App.scroll($('.create-account'));
                return false;
              });
            }
          }
        });
      }
    },

    initPopinForm: function(){
      var popinForm;

      $('.js-popin-form').magnificPopup({
        type: 'ajax',
        preloader: false,
        mainClass: 'mfp-popin-form',
        callbacks: {
          parseAjax: function(mfpResponse){
            mfpResponse.data = parseFormContent(mfpResponse.data);
          },
          ajaxContentAdded: function(){
            popinForm = this;
            $(popinForm.content).find('input').first().focus();

            var breakAjax = this.currItem.el.attr('data-break-ajax');
            if(breakAjax !== 'true')
            bindContent();
          },
          open: function(){
            var close = $(this.content).find('.mfp-close');

            close.appendTo(close.closest('.mfp-container'));
          }
        }
      });

      function parseFormContent(html) {
        return $(html).find('#popin_form');
      }

      function bindContent() {
        var form = popinForm.content.find('form'),
        submit = form.find('.form-submit');

        submit.on('click', function(e) {
          e.preventDefault();

          $.ajax({
            url: form.attr('action'),
            type: 'POST',
            data: form.serialize(),
            success: appendContent
          });

        });
      }

      function appendContent(html) {
        var close = $(popinForm.content).find('.mfp-close').detach(),
        content = parseFormContent(html);

        popinForm.content.parent().html(content);
        popinForm.content = content;
        close.appendTo(popinForm.content);

        bindContent();
      }
    },

    initSelects: function(){
      // redirect user to specific url through a select (ex: pag filters)
      $('.js-select-url').on('change', function(){
        if($(this).val())
        window.location = $(this).val();
      });

      // Init custom select
      $('.js-selectric').on('selectric-before-init', function(){ $(this).wrap('<div class="selectric-container"></div>'); });

      $('.js-selectric').selectric({
        arrowButtonMarkup: '<svg role="img" class="svg-icon chevron-down "><use xlink:href="assets/images/pictos/misc.sprite.svg#chevron-down"></use></svg>',
        optionsItemBuilder: function(itemData, element, index) {
          if(element.hasClass('js-select-url'))
          return '<a href="' + itemData.value + '">' + itemData.text +  '</a>';
          else
          return itemData.text;
        }
      });
    },

    initMfpSingleMedia: function(){
      $('.js-mfp').magnificPopup({
        type: 'image'
      });
    },

    initPortal: function(){
      enquire.register('screen and (min-width: 64em)', {
        match: function() {
          setHeight();
          $(window).resize($.throttle(250, setHeight));
        },
        unmatch: function() {
          setTimeout(function(){ $('.portal').css('height', ''); }, 250);
        }
      });

      function setHeight(){
        $('.portal').css('height', $(window).height());
      }
    },

    initImageSlider: function(){
      $('.image-slider').each(function(){
        $(this).find('.carousel__view').slick({
          autoplay: false,
          adaptiveHeight: true,
          accessibility: false, // https://github.com/kenwheeler/slick/issues/3046
          prevArrow: $(this).find('.carousel__arrow.m--prev'),
          nextArrow: $(this).find('.carousel__arrow.m--next'),
          variableWidth: true,
          centerMode: true,
        });
      });
    },

    initPortalSlider: function(){
      $('.portal-slider').each(function(){
        $(this).find('.carousel__view').slick({
          accessibility: false, // https://github.com/kenwheeler/slick/issues/3046
          autoplay: true,
          autoplaySpeed: 4000,
          arrows: false,
          dots: $('.carousel__slide').length > 1,
          fade: true,
        });
      });
    },

    initContentTab: function(){
      $('.content-tab').each(function(){
        var $togglers = $(this).find('.content-tab__toggler');
        var contents = $.map($togglers, function(toggler){ return $('.content-tab__pane' + $(toggler).attr('href')).get(0); });
        var prevIndex = 0;

        // Hide all
        $(contents).slideUp(0);

        // Open first level
        open(0);

        // Listen Click
        $togglers.on('click', function(e){
          e.preventDefault();
          close(prevIndex);
          open($togglers.index($(this)));
        });

        function open(index){
          var $toggler = $($togglers[index]);
          var $content = $(contents[index]);

          $toggler.addClass('is-active');
          $content.fadeIn(250);

          prevIndex = index;

          App.reflow($content);
        }

        function close(index){
          var $toggler = $($togglers[index]);
          var $content = $(contents[index]);

          $toggler.removeClass('is-active');
          $content.hide();
        }
      });
    },

    initKeyfigures: function(){
      var $grid = $('.js-key-figures');

      // Setup masonry on mid and large resolutions
      enquire.register('screen and (min-width: 40em)', {
        match: function() {
          $grid.masonry({
            itemSelector: '.l-grid__item',
            columnWidth: '.js-grid-sizer',
            percentPosition: true,
            horizontalOrder: true
          });
        },
        unmatch: function() {
          $grid.masonry('destroy');
        }
      });

      // Get first items (large resolution)
      var $items = $grid.children();
      var firstItems = {smalls: [], bigs: []};
      var columns = 0;
      var i = 0;

      while(columns < 4){
        if($($items[i]).hasClass('pure-u-l-1-4')){
          firstItems.smalls.push($items[i]);
          columns++;
        } else {
          firstItems.bigs.push($items[i]);
          columns += 2;
        }
        i++;
      }

      // Add margin on small elements for large resolution
      var calculateFlag;
      enquire.register('screen and (min-width: 64em)', {
        match: function() {
          calculateFlag = true;
          calculateFirstOffests();
          $(window).resize($.throttle(250, calculateFirstOffests));
          $(window).on('afterNavOpen', calculateFirstOffests);
          $(window).on('afterNavClose', calculateFirstOffests);
        },
        unmatch: function() {
          calculateFlag = false;
          $(window).off('resize', calculateFirstOffests);
          $(firstItems.smalls).css('margin-top', '');
        }
      });

      function calculateFirstOffests(){
        if(calculateFlag === true){
          $(firstItems.smalls).each(function(){
            $(this).css('margin-top', $(firstItems.bigs).height() - $(this).height());
          });

          $grid.masonry('layout');
        }
      }

    },

    initHistoryTimeline: function(){
      enquire.register('screen and (min-width: 48em)', {
        match: function() {
          $('.js-history').each(function(){
            var $timeline = $(this);

            // Create a deferred object to execute functions syncronously after slider is initialized
            var defer = $.Deferred();

            // Set equal height for all text blocks
            $('.js-history .history-point__text').matchHeight({
              byRow: false
            });

            // After updating height of text blocks set an extra margin for slides below the middle line to show this last
            $.fn.matchHeight._afterUpdate = function(){ adaptTextPosition(); };

            $timeline.find('.carousel__view').on('init', function(e, s){
              // Listen init event to recalculate text positions
              defer.then(function(){ $timeline.addClass('is-init'); return true; })
              .done(adaptTextSpreading)
              .done($.fn.matchHeight._update);

              defer.resolve();
            }).slick({
              slide: '.carousel__slide',
              slidesToShow: 3,
              autoplay: false,
              infinite: false,
              accessibility: false, // https://github.com/kenwheeler/slick/issues/3046
              prevArrow: $timeline.find('.carousel__arrow.m--prev'),
              nextArrow: $timeline.find('.carousel__arrow.m--next'),
              mobileFirst: true,
              responsive: [
                {
                  breakpoint: 850,
                  settings: {
                    slidesToShow: 4,
                  },
                },
                {
                  breakpoint: 1023,
                  settings: {
                    slidesToShow: 5,
                  },
                },
                {
                  breakpoint: 1499,
                  settings: {
                    slidesToShow: 6,
                  },
                },
                {
                  breakpoint: 1699,
                  settings: {
                    slidesToShow: 7,
                  },
                }
              ]
            });
          });



          // Reflow when nav is open or close
          $(window).on('afterNavOpen afterNavClose', function(){
            App.reflow($('.js-history'));
            adaptTextSpreading();
          });

          // Adapt text block on resize
          $(window).resize($.throttle(250, adaptTextSpreading));


        },
        unmatch: function() {
          $(window).off('resize', adaptTextSpreading);
          $('.js-history .carousel__view').slick('unslick');
          setTimeout(function(){ $('.history-point__text').css({ 'height': '', 'margin-right': '', 'margin-left': '', 'margin-top': '' }); }, 275);
          $timeline.removeClass('is-init');
        }
      });

      // Set an extra margin for slides below the middle line to show this last
      function adaptTextPosition(){
        // alert('adapt text position');
        $('.js-history .carousel__slide:odd').find('.history-point__text').each(function(){//alert($(this).outerHeight());
          $(this).css('margin-top', $(this).outerHeight() + 1);
        });

        return true;
      }

      // Adapt text block to overflow on neighbours
      function adaptTextSpreading(){
        // alert('adapt text spreading');
        $('.js-history .history-point__text').each(function(index){
          var gap = ($(this).parent().width() - 150) / 2; // 150 = width of year bubble
          if(index !== 0) $(this).css('margin-left', gap * -1); // If not first slide
          if(index !== $('.js-history .history-point__text').length-1) $(this).css('margin-right', gap * -1); // If not last slide
        });

        return true;
      }

    },

    initMDSList: function(){
      var $list = $('.mds-list');
      var $items = $('.mds-item');

      // Zebra
      zebra();

      // Sorter
      $list.stupidtable();
      $list.bind('beforetablesort', function () {
        $items.filter('.is-open').find('.js-mds-toggler').click();
      });
      $list.bind('aftertablesort', zebra);

      // Details toggler
      $items.each(function(){
        var $item = $(this);
        var $details = $item.find('.mds-item__details');
        var $toggler = $item.find('.js-mds-toggler');
        var $row;
        $toggler.click(function(){
          $item.toggleClass('is-open');
          if ($item.hasClass('is-open')) {
            $row = $('<tr class="mds-item__details-row"><td colspan="4">');
            $row.find('td').html($details.html());
            $item.after($row);
            $toggler.text('-');
          } else {
            $row.remove();
            $toggler.text('+');
          }
        });
      });

      function zebra() {
        $('.mds-item').removeClass('m--alt');
        $('.mds-item:visible').filter(':odd').addClass('m--alt');
      }
    },

    initLocsMap: function(){
      var $map = $('#locs_map');
      var $locations = $('#locs_map_locs .locs-map-loc, .js-loc');

      InfoOverlay.prototype = new google.maps.OverlayView();

      if($map.length){
        // Init map
        var map = new google.maps.Map($map.get(0), {
          center: new google.maps.LatLng(48.508310, -2.734924),
          zoom: 7,
          mapTypeId: 'roadmap',
          scrollwheel: false,
          panControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          overviewMapControl: false,
          rotateControl: false,
          zoomControl: true,
          zoomControlOptions: {
            style: google.maps.ZoomControlStyle.SMALL,
            position: google.maps.ControlPosition.LEFT_CENTER
          },
          fullscreenControl: false,
          styles: App.config.mapStyles
        });

        // Markers
        var markers = [];
        var overlay = new InfoOverlay(map);
        var currentGroup;

        var fitBounded = false;
        var minZoom = 7;
        google.maps.event.addListener(map, 'zoom_changed', function() {
          if (fitBounded) {
            if (this.getZoom() > minZoom)
              this.setZoom(minZoom);
            fitBounded = false;
          }
          if ($map.hasClass('js-international')) {
            var zoomed = this.getZoom() > 5;
            overlay.hide();
            for(var i=0; i<markers.length; i++){
              markers[i].setVisible(markers[i].agency ? zoomed : !zoomed);
            }
          }
        });

        // Set markers
        if($locations.length){
          $locations.each(createMarker);
        }

        $(window).resize($.throttle(250, centerMap));

        var $groupTogglers = $('.locs__toggler');
        if($groupTogglers.length){
          $groupTogglers.on('click', function(e){
            currentGroup = $(this).data('group');

            if(overlay.div) overlay.hide();

            for(var i=0; i<markers.length; i++){
              if(markers[i].group === currentGroup)
                markers[i].setVisible(true);
              else
                markers[i].setVisible(false);
            }

            $groupTogglers.removeClass('is-active');
            $(this).addClass('is-active');

            fitBounds();
          });

          $($groupTogglers.get(0)).trigger('click');
        } else {
          fitBounds();
        }

        var $geocodeForm = $('.locs__search');
        if($geocodeForm.length){
          // Init geocoder
          var geocoder = new google.maps.Geocoder();
          var geocodeMarker;

          $geocodeForm.on('submit', function(){
            var address = $(this).find('[name="address"]').val();

            geocodeAddress(address, map);

            return false;
          });

          if($geocodeForm.find('[name="address"]').val()){
            google.maps.event.addListenerOnce(map, 'idle', function(){
              $geocodeForm.trigger('submit');
            });
          }
        }
      }

      // Add marker on the map
      function createMarker(){
        var $this = $(this);
        var lat = parseFloat($(this).find('[itemprop="latitude"]').attr('content'));
        var lng = parseFloat($(this).find('[itemprop="longitude"]').attr('content'));
        var latlng = new google.maps.LatLng(lat, lng);

        var marker = new google.maps.Marker({
          map: map,
          position: latlng,
          icon: 'assets/images/markers/marker-default.png'
        });
        marker.agency = false;

        if(!$this.hasClass('js-no-infowindow')){
          var info = $this.get(0).outerHTML;

          google.maps.event.addListener(marker, 'click', function(){
            map.panTo(marker.getPosition());
            overlay.update(latlng, info, function(content){
              attachIOEvents(content);
              if($('main').hasClass('logged-out')) App.privateOffers.preventUnloggedUsers($(content));
            });
            overlay.show();
          });
        }

        if($this.hasClass('js-agency')) {
          marker.agency = true;
          marker.setVisible(false);
        }

        $this.data('marker', marker);
        markers.push(marker);

        var group = $this.data('group');
        if(group) marker.group = group;

        $(document).on('click', '.locs-map-io__zoom', function(){
          var bounds = new google.maps.LatLngBounds();

          var agencies = $('.js-agency[data-country="' + $(this).parent('.locs-map-loc').data('country') + '"]');
          for(var i=0; i<agencies.length; i++){
            bounds.extend(agencies.eq(i).data('marker').getPosition());
          }

          // Don't zoom in too far on only one marker : https://stackoverflow.com/questions/3334729/google-maps-v3-fitbounds-zoom-too-close-for-single-marker
          if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
            var extendPoint1 = new google.maps.LatLng(bounds.getNorthEast().lat() + 0.03, bounds.getNorthEast().lng() + 0.03);
            var extendPoint2 = new google.maps.LatLng(bounds.getNorthEast().lat() - 0.03, bounds.getNorthEast().lng() - 0.03);
            bounds.extend(extendPoint1);
            bounds.extend(extendPoint2);
          }

          fitBounded = true;
          map.fitBounds(bounds);
        });
      }

      // Auto fit map to see all markers
      function fitBounds(){
        if(markers.length){
          var bounds = new google.maps.LatLngBounds();

          for(var i=0; i<markers.length; i++){
            if((currentGroup && markers[i].group == currentGroup) || !currentGroup){
              bounds.extend(markers[i].getPosition());
            }
          }

           // Don't zoom in too far on only one marker : https://stackoverflow.com/questions/3334729/google-maps-v3-fitbounds-zoom-too-close-for-single-marker
          if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
            var extendPoint1 = new google.maps.LatLng(bounds.getNorthEast().lat() + 0.03, bounds.getNorthEast().lng() + 0.03);
            var extendPoint2 = new google.maps.LatLng(bounds.getNorthEast().lat() - 0.03, bounds.getNorthEast().lng() - 0.03);
            bounds.extend(extendPoint1);
            bounds.extend(extendPoint2);
          }

          fitBounded = true;
          map.fitBounds(bounds);
          // center = bounds.getCenter();
        }
      }

      // Listen events on Info Overlay content
      function attachIOEvents(content){
        $(content).find('button').on('click', function(){
          overlay.hide();
        });
      }

      // Center map base on overlay or all markers
      function centerMap(){
        if(overlay.div && overlay.div.style.visibility === 'visible'){
          map.setCenter(overlay.coords);
        } else {
          fitBounds();
        }
      }

      // Set a marker on given address
      function geocodeAddress(address){
        geocoder.geocode({'address': address}, function(results, status){
          if(status === 'OK'){
            // map.setCenter(results[0].geometry.location);
            // if(geocodeMarker){
            //   geocodeMarker.setPosition(results[0].geometry.location);
            // } else {
            //   geocodeMarker = new google.maps.Marker({
            //     map: map,
            //     position: results[0].geometry.location,
            //     icon: 'assets/images/markers/marker-default.png'
            //   });
            // }

            findNearestMarker(results[0].geometry.location);
          }
        });
      }

      // Find nearest marker to a position and zoom map to see both of them
      function findNearestMarker(pos){
        var closest;

        $.each(markers, function(){
          if((currentGroup && this.group == currentGroup) || !currentGroup){
            var distance = google.maps.geometry.spherical.computeDistanceBetween(this.getPosition(), pos);
            if(!closest || closest.distance > distance){
              closest = {marker: this, distance: distance};
            }
          }
        });

        if(closest){
          google.maps.event.trigger(closest.marker, 'click', false);
        }

        // var bounds = new google.maps.LatLngBounds();
        // bounds.extend(closest.marker.position);
        // bounds.extend(pos);
        // map.fitBounds(bounds);
      }

      // Custom Info Window
      function InfoOverlay(map){
        this.map = map;
        this.div = null;

        this.setMap(map);
      }

      InfoOverlay.prototype.onAdd = function(){
        var div = $('<div class="locs-map-io" style="position: absolute; visibility: hidden;"></div>').get(0);

        this.div = div;

        var panes = this.getPanes();
        panes.overlayMouseTarget.appendChild(div);
        this.hide();
      };

      InfoOverlay.prototype.draw = function(){
        if(this.coords !== undefined){
          var overlayProjection = this.getProjection();
          var pos = overlayProjection.fromLatLngToDivPixel(this.coords);
          var div = this.div;

          div.style.left = pos.x + 'px';
          div.style.top = pos.y + 'px';
        }
      };

      InfoOverlay.prototype.update = function(coords, content, callback){
        this.coords = coords;
        this.div.innerHTML = '<button class="locs-map-io__close"><svg role="img" class="svg-icon close"><use xlink:href="assets/images/pictos/misc.sprite.svg#close"></use></svg></button>' + content;
        this.draw();
        callback(this.div);
      };

      InfoOverlay.prototype.hide = function(){
        if(this.div) this.div.style.visibility = 'hidden';
      };

      InfoOverlay.prototype.show = function(){
        if(this.div) this.div.style.visibility = 'visible';
      };

      InfoOverlay.prototype.toggle = function(){
        if(this.div){
          if(this.div.visibility === 'hidden')
          this.show();
          else
          this.hide();
        }
      };

      InfoOverlay.prototype.toggleDOM = function(){
        if(this.getMap())
        this.setMap(null);
        else
        this.setMap(this.map);
      };
    },

    initPrivateOffersMap: function(){
      var $map = $('#po_map');
      var $locations = $('#po_map_locs .po-map-loc');

      InfoOverlay.prototype = new google.maps.OverlayView();

      if($map.length){
      // Init map
      var map = new google.maps.Map($map.get(0), {
        center: new google.maps.LatLng(48.508310, -2.734924),
        zoom: 7,
        mapTypeId: 'roadmap',
        scrollwheel: true,
        panControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        overviewMapControl: false,
        rotateControl: false,
        zoomControl: true,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.SMALL,
          position: google.maps.ControlPosition.LEFT_CENTER
        },
        fullscreenControl: false,
        styles: App.config.mapStyles
      });

      // Init markers
      var markers = [];
      var overlay = new InfoOverlay(map);
      // Set markers
      if($locations.length){
        $locations.each(createMarker);
      }

      fitBounds();

      $(window).resize($.throttle(250, centerMap));
    }

    // Add marker on the map
    function createMarker(){
      var lat = parseFloat($(this).find('[itemprop="latitude"]').attr('content'));
      var lng = parseFloat($(this).find('[itemprop="longitude"]').attr('content'));
      var latlng = new google.maps.LatLng(lat, lng);

      var marker = new google.maps.Marker({
        map: map,
        position: latlng,
        icon: 'assets/images/markers/marker-default.png'
      });

      if(!$(this).hasClass('js-no-infowindow')){
        var info = $(this).get(0).outerHTML;

        google.maps.event.addListener(marker, 'click', function(){
          map.panTo(marker.getPosition());
          overlay.update(latlng, info, function(content){
            attachIOEvents(content);
            if($('.main').hasClass('logged-out')) App.privateOffers.preventUnloggedUsers($(content));
          });
          overlay.show();
        });
      }

      markers.push(marker);
    }

    // Auto fit map to see all markers
    function fitBounds(){
      if(markers.length){
        var bounds = new google.maps.LatLngBounds();

        for(var i=0; i<markers.length; i++){
          bounds.extend(markers[i].getPosition());
        }

         // Don't zoom in too far on only one marker : https://stackoverflow.com/questions/3334729/google-maps-v3-fitbounds-zoom-too-close-for-single-marker
        if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
          var extendPoint1 = new google.maps.LatLng(bounds.getNorthEast().lat() + 0.03, bounds.getNorthEast().lng() + 0.03);
          var extendPoint2 = new google.maps.LatLng(bounds.getNorthEast().lat() - 0.03, bounds.getNorthEast().lng() - 0.03);
          bounds.extend(extendPoint1);
          bounds.extend(extendPoint2);
        }

        map.fitBounds(bounds);
        // center = bounds.getCenter();
      }
    }

    // Listen events on Info Overlay content
    function attachIOEvents(content){
      $(content).find('button').on('click', function(){
        overlay.hide();
      });
    }

    // Center map base on overlay or all markers
    function centerMap(){
      if(overlay.div && overlay.div.style.visibility === 'visible'){
        map.setCenter(overlay.coords);
      } else {
        fitBounds();
      }
    }

    // Custom Info Window
    function InfoOverlay(map){
      this.map = map;
      this.div = null;

      this.setMap(map);
    }

    InfoOverlay.prototype.onAdd = function(){
      var div = $('<div class="po-map-io" style="position: absolute; visibility: hidden;"></div>').get(0);

      this.div = div;

      var panes = this.getPanes();
      panes.overlayMouseTarget.appendChild(div);
      this.hide();
    };

    InfoOverlay.prototype.draw = function(){
      if(this.coords !== undefined){
        var overlayProjection = this.getProjection();
        var pos = overlayProjection.fromLatLngToDivPixel(this.coords);
        var div = this.div;

        div.style.left = pos.x + 'px';
        div.style.top = pos.y + 'px';
      }
    };

    InfoOverlay.prototype.update = function(coords, content, callback){
      this.coords = coords;
      this.div.innerHTML = '<button class="po-map-io__close"><svg role="img" class="svg-icon close"><use xlink:href="assets/images/pictos/misc.sprite.svg#close"></use></svg></button>' + content;
      this.draw();
      callback(this.div);
    };

    InfoOverlay.prototype.hide = function(){
      if(this.div) this.div.style.visibility = 'hidden';
    };

    InfoOverlay.prototype.show = function(){
      if(this.div) this.div.style.visibility = 'visible';
    };

    InfoOverlay.prototype.toggle = function(){
      if(this.div){
        if(this.div.visibility === 'hidden')
        this.show();
        else
        this.hide();
      }
    };

    InfoOverlay.prototype.toggleDOM = function(){
      if(this.getMap())
      this.setMap(null);
      else
      this.setMap(this.map);
    };
  },

  scroll: function(element, offset){
    offset = offset === undefined ? App.config.scrollOffset : offset;

    $('html, body').animate({ scrollTop: $(element).offset().top - offset}, 'slow');
  },

  Tac: function() {

    window.tarteaucitronForceLanguage = $('html').attr('lang');
    tarteaucitron.init({
      "hashtag": "#tac",
      "highPrivacy": false,
      "orientation": "bottom",
      "adblocker": false,
      "showAlertSmall": false,
      "cookieslist": true,
      "removeCredit": true
    });

    tarteaucitron.user.gtagUa = "UA-2805587-2";
    (tarteaucitron.job = tarteaucitron.job || []).push("gtag");

    tarteaucitron.user.googlemapsKey = "AIzaSyDzh-SznOqTYKBvvVL7Ju4ocaYyArkPMAs";
    tarteaucitron.user.mapscallback = "gmap_callback";
    (tarteaucitron.job = tarteaucitron.job || []).push("googlemaps");

    // (tarteaucitron.job = tarteaucitron.job || []).push("youtube");

    window.gmap_callback = function() {
      if($('main[class^=p-private-offers]').length) {
        App.initPrivateOffersMap();
      } else {
        App.initLocsMap();
      }
    };
  },

  gtag: function(action, category, label, value) {
    if(window.gtag !== undefined) {
      window.gtag('event', action, {
        'event_category': category,
        'event_label': label,
        'value': value
      });
    }
  },

};
// ---------- APP END
window.App = App;

// Init app
$(document).ready(App.init);
// $(window).load(App.load); // jQuery 2
$(window).on('load', App.load); // jQuery 3

App.Tac();

})(jQuery);

window.App.config.mapStyles = [
  {
    "featureType": "landscape",
    "stylers": [
      {
        "hue": "#FFBB00"
      },
      {
        "saturation": 43.400000000000006
      },
      {
        "lightness": 37.599999999999994
      },
      {
        "gamma": 1
      }
    ]
  },
  {
    "featureType": "road.highway",
    "stylers": [
      {
        "hue": "#FFC200"
      },
      {
        "saturation": -61.8
      },
      {
        "lightness": 45.599999999999994
      },
      {
        "gamma": 1
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "stylers": [
      {
        "hue": "#FF0300"
      },
      {
        "saturation": -100
      },
      {
        "lightness": 51.19999999999999
      },
      {
        "gamma": 1
      }
    ]
  },
  {
    "featureType": "road.local",
    "stylers": [
      {
        "hue": "#FF0300"
      },
      {
        "saturation": -100
      },
      {
        "lightness": 52
      },
      {
        "gamma": 1
      }
    ]
  },
  {
    "featureType": "water",
    "stylers": [
      {
        "hue": "#0078FF"
      },
      {
        "saturation": -13.200000000000003
      },
      {
        "lightness": 2.4000000000000057
      },
      {
        "gamma": 1
      }
    ]
  },
  {
    "featureType": "poi",
    "stylers": [
      {
        "hue": "#00FF6A"
      },
      {
        "saturation": -1.0989010989011234
      },
      {
        "lightness": 11.200000000000017
      },
      {
        "gamma": 1
      }
    ]
  }
];
